.vapi-button {}

/* Call Agent button style */
.vapi-button.call-now {
    background-color: none;
}

.vapi-button.call-now:hover {
    background-color: none;
}

.vapi-button.call-now:active {
    background-color: none;
}

/* Connecting button style */
.vapi-button.connecting {
    background-color: #ffd700 !important;
    /* Gold */
}

.vapi-button.connecting:hover {
    background-color: #e6c200 !important;
}

.vapi-button.connecting:active {
    background-color: #ccac00 !important;
}

/* End call button style */
.vapi-button.end-call {
    background-color: #f70606 !important;
    /* LimeGreen */
}

.vapi-button.end-call:hover {
    background-color: #f70606 !important;
}

.vapi-button.end-call:active {
    background-color: #f70606 !important;
}