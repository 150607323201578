.swal-button--confirm {
    background-color: red !important;
}

/* .swal-icon--success {
    border-color: red;
}

.swal-icon--success:before,
.swal-icon--success:after {
    background: red;
} */

.swal-icon--success__line {
    background: red !important;
}

.swal-icon--success__ring {
    border-color: red;
}