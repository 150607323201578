/* App.css */

.chatbot-toggle-button {
    position: fixed;
    bottom: 15px;
    right: 15px;
    background-color: #5cbd9d;
    color: white;
    border: none;
    padding: 15px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 100001;
}

.chatbot-container {
    position: fixed;
    bottom: 90px;
    right: 15px;
    z-index: 100001;
}

.react-chatbot-kit-chat-container {
    min-width: 350px;
    max-width: 400px;
}

.react-chatbot-kit-chat-inner-container {}

.react-chatbot-kit-chat-header {}

.react-chatbot-kit-chat-input-container {}

.react-chatbot-kit-chat-message-container {}

.react-chatbot-kit-chat-input {}

.react-chatbot-kit-chat-input-form {}

.react-chatbot-kit-chat-input::placeholder {}

.react-chatbot-kit-chat-btn-send {}

.react-chatbot-kit-chat-btn-send-icon {}

.react-chatbot-kit-chat-bot-message-container {}

.react-chatbot-kit-chat-bot-avatar-container {}

.react-chatbot-kit-chat-bot-avatar-icon {}

.react-chatbot-kit-chat-bot-avatar-letter {}

.react-chatbot-kit-chat-bot-message {
    width: 100%;
    font-size: 0.8rem;
}

.react-chatbot-kit-chat-bot-message-arrow {}

.react-chatbot-kit-chat-bot-loading-icon-container {}

.chatbot-loader-container {}

#chatbot-loader #chatbot-loader-dot1 {}

#chatbot-loader #chatbot-loader-dot2 {}

#chatbot-loader #chatbot-loader-dot3 {}

.react-chatbot-kit-error {}

.react-chatbot-kit-error-container {}

.react-chatbot-kit-error-header {}

.react-chatbot-kit-error-docs {}